<template>
  <div class="ifram-box">
    <iframe
      v-if="!isPdf"
      :src="previewSrc"
      width="100%"
      height="100%"
      frameborder="1"
      sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"
    ></iframe>
    <iframe
      v-else
      :src="previewSrc"
      width="100%"
      height="100%"
      frameborder="1"
    ></iframe>
    <!-- 避免ifrime sandbox属性报错 -->
    <!-- sandbox="allow-scripts allow-top-navigation allow-same-origin" -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      previewSrc: '',
      permission: 'allow-top-navigation allow-same-origin',
      isPdf: false
    }
  },
  mounted() {
    if (['pdf'].includes(this.$route.query.type)) {
      this.isPdf = true
      this.previewSrc = this.$route.query.file
    } else if (['link'].includes(this.$route.query.type)) {
      // 网页类型，直接预览
      this.isPdf = true
      document.title = this.$route.query.title
      this.previewSrc = this.$route.query.file
    } else {
      this.isPdf = false
      // 权限管理
      this.previewSrc =
        'https://view.officeapps.live.com/op/view.aspx?src=' +
        this.$route.query.file
    }
  }
}
</script>

<style lang="less" scoped>
.ifram-box {
  width: 100vw;
  height: 100vh;
  iframe {
    height: 100%;
    width: 100%;
  }
}
</style>
